.input{
	padding: 6px;
	border: solid thin #ccc;
	border-radius: 3px;
	line-height: 16px;
}
.input_item {
	display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
	align-items: center;
    
}

.input_item {
	padding: 5px;
	width: 100%;
}
.input_item:hover .input_item_text{
	font-weight: bold;
}
.input_item_input{
	padding: 5px;
	border-radius: 4px;
    border: solid thin #ccc;
	
}
.input_item_text{
	
}

.input_image{
	&:hover{
		.input_image_control{
			display: flex;
		}
	}
	.input_image_control{
		display: none;
		i {
			font-size: 32px;
		}
	}
}

.bootstrap-datetimepicker-widget {
	height: max-content;
}
.p-inputtext {
    padding: 5px;
    border: solid thin #ccc;
    background: white;
}

			