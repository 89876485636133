.arrow-down-red {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 10px solid #f00;
}

.arrow-down-green {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 10px solid green;
}

.arrow-down-yellow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 10px solid #896238;
}

.text-color-red{
    position : absolute ;
    top : -25px ;
    right : -3px ;
    font-size : 10px;
    color: red;
    white-space: nowrap;
}

.percenter.text-color-red{
    right : -8px ;
}
.percenter.text-color-green{
    right : -8px ;
}
.text-color-green{
    position : absolute ;
    top : -25px ;
    right : -2px ;
    font-size : 10px;
    color: green;
    white-space: nowrap;
}

.text-color-yellow{
    position : absolute ;
    top : -25px ;
    right : -2px ;
    font-size : 10px;
    color: #896238;
    white-space: nowrap;
}

