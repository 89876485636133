.field_cus{
    margin-bottom: 1rem;
}
.field_cus > label{
    display: block;
    margin-bottom: 0.5rem;
}

.field_cus .p-filled{
    width: 100%;
}
.field_cus .p-calendar{
    width: 100%;
}

.field_cus > input,textarea{
    width: 100%;
}